.skipn {
	position: absolute;
	bottom: 150px;
}

.skipn:focus {
	bottom: -30px;
	left: 1rem;
}
.menu_mobile li {
	display: flex;
	justify-content: center;
}

.first-h1 {
	position: absolute;
	top: 0;
	left: 0;
	margin: 1.2rem;
}

.home__scroll-button {
	color: #fff;
	transition: 0.3s;
	display: flex;
	justify-content: center;
}

.home__scroll-mouse {
	width: 100px;
	height: 2.5rem;
	width: 2rem;
	color: translucid;
	position: absolute;
	margin-left: 0px;
	margin-right: 0px;
	top: 80%;
	border: 1.8px solid;
	border-radius: 50%;
	cursor: pointer;
	box-shasdow: 0 0px 4px -2.5px #fff;
	text-shadow: 0 0 #fff;
	overflow: hidden;
}

.home__scroll-mouse::before,
.home__scroll-mouse::after {
	content: "";
	position: absolute;
	top: 15%;
	border: 1.8px solid #fff;
	height: 0.7rem;
	width: 0.7rem;
	transform: translate(-50%, -50%) rotate(45deg);
	border-top: transparent;
	border-left: transparent;
	box-shadow: 1px 0px 8px -2.5px #fff;
	animation: scroll-down 1.05s ease-in-out infinite;
}

.home__scroll-mouse:hover ~ .home__scroll-name {
	transition: all 1s;
	box-shadow: 1px 1px 8px 4px #fff;
}

.background_nav {
	background-color: rgba(0, 0, 0, 0.25);
}

.title-wrap {
	margin-bottom: -100px;
	color: aliceblue;
}

.services-item:hover h3,
.services-item:hover div {
	color: rgba(229, 245, 0, 1);
}

.lg-title {
	font-size: 28px;
	margin-top: 50px;
}

#Travel {
	display: none;
}

.services {
	display: flex;
	justify-content: center;
	flex-direction: column;
	bottom: 100px;
	margin: 0 auto;
	color: #fff;
	text-align: center;
	margin-bottom: 30px;
}

.services-icon {
	margin: 0 auto;
	font-size: 3.5rem;
}

#Places img {
	width: 100%;
	transition: 0.5s all ease-in-out;
	height: 350px;
}

#Places .selectCard:hover img {
	transform: scale(1.5);
}
div .selectCard {
	overflow: hidden;
}

@media screen and (min-width: 740px) {
	h3 {
		font-size: 28px;
	}
	span {
		display: inherit;
		font-size: 20px;
		margin-top: 10px;
	}

	span:nth-child(2n) {
		margin-top: 0px;
	}

	.fly__icon {
		font-size: 27px;
	}
	.lg-title {
		font-size: 38px;
	}
	.services-icon {
		font-size: 5rem;
		margin-bottom: 1rem;
	}
	.fly__icon {
		position: relative;
		left: 0px;
	}

	#Viewer {
		margin-bottom: 0px;
		max-width: initial;
		overflow: hidden;
	}
	#Travel {
		display: flex;
	}
}

@media screen and (min-width: 1240px) {
	span {
		font-size: 24px;
	}

	.lg-title {
		margin-top: 200px;
	}
	.services-icon {
		font-size: 9rem;
	}
}
